* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: black;
}

body {
  font-family: "Poppins", sans-serif;
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-size: cover;
  background-position: center;
  background-color: lightblue;
  font-size: xx-large;
}

.header p {
  font-size: 20px;
  font-weight: 400;
  color: rgb(79, 79, 151);
  top: 240px;
}

.menu-icon {
  position: absolute;
  top: 400px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
  color: blue;
}

.close-button {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 30px;
  cursor: pointer;
  color: blue;
}

.side-menu {
  position: fixed;
  top: 0;
  right: -400px;
  width: 350px;
  height: 100%;
  background-color: rgba(211, 221, 221, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: space-between;
  text-align: left;
  transition: right 0.3s ease;
  background-color: magenta;
}

.side-menu.open {
  right: 0;
}

.menu-list {
  list-style: none;
  padding: 50px 20px;
  font-size: 30px;
  color: #fff;
  text-align: left;
}

ul {
  list-style: none;
  padding: 0 15px;
  text-align: left;
}

ul li {
  margin: 20px 0;
  cursor: pointer;
  font-weight: 300;
  color: #fff;
}

.left {
  width: 100%;
  text-align: center;
}

.title h1 {
  color: black;
  font-size: 60px;
  margin-bottom: 10px;
}

.description {
  font-size: 40px;
  color: black;
  position: relative;
  margin-top: 20px;
}

p.description {
  text-align: center;
  margin-top: 20px;
}

.right img {
  height: auto;
  width: 700px;
  margin-top: 60px;
}


@media screen and (min-width: 300px) and (max-width:768px) {
  .header {
    height: 50vh;
    padding: 20px;
    text-align: center;
  }

  .header p {
    top: 40px;
    font-size: 12px;
    margin-bottom: 100px;
    width: 90vw;
  }

  .title h1 {
    margin-top: 40px;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .right img {
    width: 100%;
    margin-top: 50px;
  }

  .menu-icon {
    top: 20px;
    right: 20px;
    font-size: 24px;
  }

  .side-menu {
    width: 90%;
    padding-top: 80px;
    height: 50vh;
  }

  .side-menu.open {
    right: 0;
  }

  .menu-list {
    padding: 20px;
    font-size: 20px;
  }

  .menu-list li {
    margin: 10px 0;
  }

  .close-button {
    top: 3px;
  }

}




@media screen and (max-width:768px) {
  .header {
    height: 50vh;
    padding: 20px;
    text-align: center;
  }

  .header p {
    top: 100px;
    font-size: 12px;
    margin-bottom: 100px;
    width: 90vw;
  }

  .title h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .right img {
    width: 100%;
    margin-top: 50px;
  }

  .menu-icon {
    
    top: 20px;
    right: 20px;
    font-size: 24px;
  }

.side-menu {
  width: 90%;
  padding-top: 80px;
  height: 50vh;

}

.side-menu.open {
  right: 0;
}

  .menu-list {
    padding: 20px;
    font-size: 20px;
  }

  .menu-list li {
    margin: 10px 0;
  }
  .close-button{
    top: 3px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .title h1 {
    font-size: 50px;
    top: 20px;
  }

  p.description {
    font-size: 40px;
    margin-top: 90px;
    top: 50px;
  }

  .header {
    height: 70vh;
  }

  .header h1 {
    color: black;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .header p {
    font-weight: 400;
    margin-bottom: 80px;
    width: 70vw;
    font-size: 20px;
    color: rgb(79, 79, 151);
    top: 170px;
  }

  .side-menu {
    height: 70vh;
  }

  .menu-icon {
    align-items: center;
    top: 300px;
    color: blue;
  }

  .right img {
    height: auto;
    width: 600px;
    margin-top: 50px;
  }

  .menu-list li {
    font-size: 20px;
  }
}

@media screen and (min-width:1400px) and (max-width:2300){

}