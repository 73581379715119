.carousel-container {
  text-align: center;
  padding: 50px 0;
  background-color:  plum;
  
}

.page-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: rgb(109, 29, 109);
  
}

.slick-slide {
  display: flex;
  justify-content: center;
}

.carousel-card {
  background-color: rgb(109, 29, 109);
  border-radius: 20px;
  padding: 20px;
  margin: 0 auto;
  width: 80%;
  max-width: 500px; /* Set maximum width for the carousel cards */
}

.carousel-card img {
  max-width: 100%;
  height: auto;
  border-radius: 20px; /* Curving the images inside the cards */
}

.card-content {
  margin-top: 20px;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

.card-description {
  font-size: 16px;
  color: white;
}

.go-back-btn {
  background-color: yellow;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.go-back-btn:hover {
  background-color: #ff0;
}


@media screen and (max-width: 768px) {
  .carousel-card {
    padding: 30px; /* Adjust padding for smaller screens */
    max-width:fit-content;
    height: 43vh;
    margin-left: 10px;
    margin-right: 10px;
    
  }
  .carousel-card img{
   height: 30vh;
   margin-left: 40px;
  }
  .card-description p{
    margin-bottom: 60px;
  }

}
