.footer {
    background-color: lightblue;
    color: white;
    padding: 20px 0;
    padding-left: 50px;
    padding-right: 30px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: black;
  }
  
  .footer-section {
    flex: 1;
    color: black;
  }
  .footer-section1{
    margin-right: 20px;
    
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
    color: black;
  }
  
  .footer-section p {
    margin: 5px 0;
    color: black;
  }
  
  .copyright {
    text-align: center;
    margin-top: 20px;
    color: white;
    
  }

  @media screen and (max-width: 768px){
    .footer-content{
        display: flex;
        flex-direction: column;
    }
  }