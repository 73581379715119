.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.form-container,
.image-container {
  flex: 1; /* Equal width for both containers */
}

.title {
  text-align: center;
  margin-bottom: 30px;
  color: rgb(31, 4, 31);
}

.form-group {
  margin-bottom: 20px;
  color: rgb(54, 9, 54);
}

.form-group label {
  display: block;
  color: rgb(54, 9, 54);
  font-size: 20px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
}

.send-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: purple;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgb(54, 9, 54);
}

.confirmation {
  text-align: center;
}

.confirmation button {
  margin-top: 10px;
}

.image-container {
  margin-left: 20px; /* Space between form and image */
}

.image-container img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .contact-form-container {
    flex-direction: column;
    padding: 20px;
  }

  .form-container,
  .image-container {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
  
}
